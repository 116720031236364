import {connectSerial} from '../SerialPort'
import Shtrikh from './Shtrikh';
import VikiPrint from './VikiPrint';

export default class Kkm{
    constructor(){
        this.posImpl = null;
        this.v2 = false;
    }
    isConnected(){ 
        return this.posImpl != null;
    }
    getInn(){
        if (this.posImpl)
            return this.posImpl.getInn()
        return null;
    }
    setV2(val){
        this.v2=val;
        if (this.posImpl)
            this.posImpl.v2=val
    }

    setLogger(logger){
        console.log('logger', logger)
        if (this.posImpl)
            this.posImpl.logger = logger
    }
    async connect(manual=false){
        const filters = [
            { usbVendorId: 0x1fc9, usbProductId: 0x0083 }, // Штрих-М
            { usbVendorId: 0x0483, usbProductId: 0x5740 }, // VikiPrint
            //{ usbVendorId: 0x18d1, idProduct: 0x4ee4},
            { usbVendorId: 0x1a86, usbProductId: 0x7523 }, // VikiPrint 80 over ch341-uart converter
            { usbVendorId: 0x10c4, usbProductId: 0xea60 }, // VikiPrint 80 over cp210x uart converter
        ];
        const serial = await connectSerial(manual, filters, {baudRate: 57600}) // {baudRate: 4800})  115200

        if (serial){
            const writer = await serial.writable.getWriter();
            const reader = await serial.readable.getReader();

            const info = serial.getInfo();
            if (info.usbVendorId==0x1fc9 && info.usbProductId==0x0083){
                this.posImpl = new Shtrikh(serial, writer, reader)
                console.log('Штрих-М detected', serial)
            }
            else if (info.usbVendorId==0x0483 && info.usbProductId==0x5740){
                this.posImpl = new VikiPrint(serial, writer, reader)
                console.log('VikiPrint detected', serial)
                await this.posImpl.checkStatus()
            }
            else{
                this.posImpl = new VikiPrint(serial, writer, reader)
                console.log('VikiPrint guess', serial, info)
            }
        }
    }

    async disconnect(){
        await this.posImpl.reader.cancel()
        await this.posImpl.reader.releaseLock();

        await this.posImpl.writer.releaseLock();
        await this.posImpl.serial.close();
        this.posImpl = null;
    }

    async proccess(command){
    }

    async startWork(){
        return await this.posImpl.startWork()
    }

    async income(amount, price, name, taxGroup=0, mark=null){
        return await this.posImpl.income(amount, price, name, taxGroup, mark);
    }

    async retrn(amount, price, name){
        return await this.posImpl.retrn(amount, price, name);
    }

    async beep(){
        return await this.posImpl.beep();
    }

    async printCopyOfLastCheque(){
        return await this.posImpl.printCopyOfLastCheque();
    }


    async getPosStatus(){
        return await this.posImpl.getPosStatus()
    }

    async openCheck(mode){
        return await this.posImpl.openCheck(mode)
    }

    async closeCheck(total, totalCard){
        return await this.posImpl.closeCheck(total, totalCard)
    }

    async pay(type, total, text){
        return await this.posImpl.pay(type, total, text)
    }

    async nullingCheck(total){
        return await this.posImpl.nullingCheck(total)
    }

    async pullPaper(lines){
        return await this.posImpl.pullPaper(lines);
    }

    async cutPaper(mode){
        return await this.posImpl.cutPaper()
    }

    async openShift(cashier){
        return await this.posImpl.openShift(cashier)
    }

    async closeShift(){
        return await this.posImpl.closeShift()
    }

    async cancelCheck(){
        return await this.posImpl.cancelCheck()
    }

    async extError(){
        return await this.posImpl.extError()
    }

    async printLine(text){
        return await this.posImpl.printLine(text)
    }

    async XReport(){
        return await this.posImpl.XReport()
    }

    async getShortPosStatus(){
        return await this.posImpl.getShortPosStatus()
    }

    async ZReport(){
        return await this.posImpl.ZReport()
    }

    async getShiftInfo(){
        return await this.posImpl.getShiftInfo()
    }

    async commonDumping(){
        return await this.proccess(this.getCommandCommonDumping())
    }

    async isShiftOpened(){
        if (this.posImpl)
            return await this.posImpl.isShiftOpened()
        else
            return null
    }

    async is24HoursEnded(){
        if (this.posImpl)
            return await this.posImpl.is24HoursEnded()
        return null
    }

    async isPaperPresent(){
        if (this.posImpl)
            return await this.posImpl.isPaperPresent()
        return null
    }

    async isWaitingAfterPaperOut(){
        if (this.posImpl)
            return await this.posImpl.isWaitingAfterPaperOut()
        return null
    }

    async getSerialNumber(){
        return await this.posImpl.getSerialNumber()
    }

    async getShiftNumber(){
        return await this.posImpl.getShiftNumber()
    }

    async getChequeNumber(){
        return await this.posImpl.getChequeNumber()
    }

    async continuePrint(){
        if (this.posImpl instanceof Shtrikh)
            await this.posImpl.continuePrint()
    }

    async getCashTotal(){
        return await this.posImpl.getCashTotal()
    }

    async getCashTotalX(){
        return await this.posImpl.getCashTotalX()
    }

    async getShiftOpeningDateTime(){
        return await this.posImpl.getShiftOpeningDateTime()
    }

    async getExchangeStatus(){
        await this.posImpl.getExchangeStatus()
    }

    async getDateOfFirstUnsended(){
        if (this.posImpl instanceof Shtrikh){
            const exchangeStatus = await this.posImpl.getExchangeStatus()
            return exchangeStatus.firstUnsended
        }
        else if (this.posImpl instanceof VikiPrint){
            return await this.posImpl.getDateOfFirstUnsended()
        }
    }

    async getFNExpiryDate(){
        const info = await this.posImpl.getFNExpiryDate()
        return info.expiryDate
    }

    getKkmName(){
        if (this.posImpl)
            if (this.posImpl instanceof Shtrikh)
                return 'Штрих-М'
            else if (this.posImpl instanceof VikiPrint)
                return 'Вики-принт'
            else
                return 'неизвестный'
    }
}
