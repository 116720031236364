import {ENQ, STX, ACK, ETX} from './PosImpl.js'
import PosImpl from './PosImpl.js'
import {PullPaper, Beep, StartWork, XReport, OpenShift, CloseShift, OpenCheck, CloseCheck, NullingCheck, 
    Income, Pay, GetPosStatus, CutPaper, ExtError, GetCounters, GetDateTime, GetInfo, GetPrinterStatus, 
    GetExchangeStatus, AdditionalInfo, CheckKm, IncludeKm, SetKm, tag2106} from './CommandViki.js'

//var iconv = require('iconv-lite');
import iconv from "iconv-lite" 

class NoMoreData extends Error {
    constructor(message = "", ...args) {
      super(message, ...args);
      this.message = message + " has no more data.";
    }
}

export class UnintendedData extends Error {
}

export default class VikiPrint extends PosImpl{
    constructor(serial, writer, reader){
        super(serial, writer, reader);
        this.packId = 0x20;
        this.crc = 0x00;
        //this.checkStatus()
        //this.getDateTime()
        //this.checkStatus().then(()=>this.getDateTime())
    }

    async getByte() {
        if (!this.queue.length)
            await this.read();
        let b=this.queue.shift();
        this.crc = this.xorControlSum(this.crc, b)
        return b;
    }

    async checkNextByte(){
        if (!this.queue.length)
            await this.read();
        if (this.queue.length)
            return this.queue[0];
    }

    async getTwoByteString() {
        let byteLow = await this.getByte()
        let byteHigh = await this.getByte()
        return String.fromCharCode(byteLow) + String.fromCharCode(byteHigh);
    }

    async proccess(command){
        await this.sendСommand(command);
        return await this.waitAnswer(command);
    }

    async checkStatus(){
        const {fatalState, currentFlags, documentStatus} = await this.proccess(new GetPosStatus())
        if (fatalState!=0)
            throw new Error("fatalState: "+ fatalState.toString())
        if (currentFlags& 0b00000001){ // no started work
            await this.proccess(new StartWork())
        }
    }
    async sendСommand(command){
        if (command.getCode()==0x0A){
            await this.write(0x0A);
            return
        }

        if (command instanceof XReport){
            await this.checkStatus()
        }
        const header = new Uint8Array(6)
        let idx=0
        header[idx++] = STX
        header[idx++] = 80 // P
        header[idx++] = 73 // I
        header[idx++] = 82 // R
        header[idx++] = 73 // I
        header[idx++] = this.packId++
        if (this.packId==0xF0)
            this.packId = 0x20;
        await this.writeData(header);
        let crc = this.xorControlSum(0, header, 1)
        const cmdData = command.getData()

        await this.writeData(cmdData);
        await this.write(ETX);
        crc = this.xorControlSum(crc, cmdData) ^ ETX
        let crc2=crc.toString(16).toUpperCase() 
        if (crc2.length==1)
            crc2 = '0' + crc2
        await this.write(crc2.charCodeAt(0));
        await this.write(crc2.charCodeAt(1));
    }

    async waitAnswer(command=null){
        if (command instanceof PullPaper){
            return null
        }
        let stx = await this.getByte()
        while (stx!=STX){
            stx = await this.getByte()
            console.log("stx: ", stx)
        }
        this.crc = 0x00;
        const packId = await this.getByte();
        const commandCode = await this.getTwoByteString()
        console.log('commandCode: ' + commandCode + 'h');
        if (command.getCode().toString(16).padStart(2,'0')!=commandCode){
            throw new Error("answer from another command")
        }
        const errCode = await this.getTwoByteString()
        /*if (command instanceof GetInfo){
            debugger
        }*/
        if (errCode=='00')
            console.log('no error');
        else
            throw new Error(this.getErrorText(errCode))
            // throw new Error('errorCode: ' + errCode + 'h'+"\n" )

        let ret = await command.readAnswer(this)
        // if (this.queue.length>0 && this.queue[0]==ETX) // skip ETX not shifted by last data parameter
        const etx = await this.getByte()
        if (etx!=ETX){
            console.log('ETX waiting, got', etx, etx.toString(16))
            throw new Error("ETX waiting, got" + etx)
        }

        //const crc =  (this.crc & 0x0f).toString(16).charAt(0).toUpperCase() + (this.crc >> 4).toString(16).charAt(0).toUpperCase()
        let crc =  this.crc.toString(16).toUpperCase()
        if (crc.length==1)
            crc = '0' + crc
        const rCrc = await this.getTwoByteString()
        if (crc != rCrc){
            console.log("crc: ", crc)
            console.log("rCrc: ", rCrc)
            throw new Error("crc corrupted")
        }
        if (errCode != '00'){
            if (errCode == '01' || errCode == '03'){
                await this.proccess(new ExtError()) 
            }
            throw new Error(this.getErrorText(errCode))
        }
        return ret;
    }

    getErrorText(code){
        switch (code){
            case '01': return 'Функция невыполнима при данном статусе ККТ'
            case '02': return 'В команде указан неверный номер функции'
            case '03': return 'Некорректный формат или параметр команды (для получения доп. информации вызовите команду 0x06/1)'
            case '04': return 'Переполнение буфера коммуникационного порта'
            case '05': return 'Таймаут при передаче байта информации'
            case '06': return 'В протоколе указан неверный пароль'
            case '07': return 'Ошибка контрольной суммы в команде'
            case '08': return 'Конец чековой ленты'
            case '09': return 'Принтер не готов'
            case '0A': return 'Текущая смена больше 24 часов. Установка даты времени больше чем на 24 часа.'
            case '0B': return 'Разница во времени, ККТ и указанной в команде начала работы, больше 8 минут'
            case '0C': return 'Вводимая дата более ранняя, чем дата последней фискальной операции'
            case '0E': return 'Отрицательное значение результирующего счетчика (недостаточная сумма денег в ККТ)'
            case '0F': return 'Для выполнения команды необходимо закрыть смену'
            case '10': return 'Нет данных в журнале'
            case '11': return 'Ошибка контрольной ленты'
            case '12': return 'Ошибка посылки данных в ОФД'
            case '20': return 'Фатальная ошибка ККТ. Причины возникновения данной ошибки можно уточнить в ”Статусе фатальных ошибок ККТ”'
            case '41': return 'Некорректный формат или параметр команды ФН'
            case '42': return 'Некорректное состояние ФН'
            case '43': return 'Ошибка ФН'
            case '44': return 'Ошибка КС (Криптографического сопроцессора) в составе ФН'
            case '45': return 'Исчерпан временной ресурс использования ФН'
            case '46': return 'ФН переполнен'
            case '47': return 'Неверные дата или время в ФН'
            case '48': return 'Нет запрошенных данных в ФН'
            case '49': return 'Некорректное значение параметров команды ФН'
            case '4A': return 'Некорректная команда ФН'
            case '4B': return 'ККТ передает в ФН данные, которые должен формировать ФН'
            case '4C': return 'ККТ передает в ФН данные, которые уже были переданы в составе данного документа'
            case '4D': return 'Отсутствуют данные, необходимые для корректного учета в ФН'
            case '4E': return 'Количество позиций в документе ФН превысило допустимый предел'
            case '50': return 'Превышен размер данных TLV'
            case '51': return '	Нет транспортного соединения'
            case '52': return 'Исчерпан ресурс КС'
            case '54': return 'Может быть выдан в нескольких случаях, в сочетании с флагами предупреждений. Если стоит флаг "Превышено время ожидания ответа ОФД", то это значит, что время нахождения в очереди самого старого сообщения на выдачу более 30 календарных дней, необходимо передать сообщения в ОФД (Только при ККТ в режиме передачи данных). Если стоит флаг "Архив ФН заполнен на 90 %", то это означает, что Архив ФН полностью заполнен – необходимо закрыть ФН. Если флаги предупреждений отсутствуют, то это означает, что ресурс 30 дневного хранения для документов для ОФД исчерпан'
            case '55': return 'Время нахождения в очереди самого старого сообщения на выдачу более 30 календарных дней.'
            case '56': return 'Продолжительность смены ФН более 24 часов'
            case '57': return 'Разница более чем на 5 минут отличается от разницы, определенной по внутреннему таймеру ФН.'
            case '58': return 'Некорректный реквизит, переданный ККТ в ФН'
            case '59': return 'Переданный в ФН реквизит не соответствует установкам при регистрации'
            case '60': return 'Неверное сообщение от ОФД'
            case '61': return 'Нет связи с ФН'
            case '62': return 'Ошибка обмена с ФН'
            case '63': return 'Слишком длинная команда для посылки в ФН'
            default: return code
        }
    }

    async getInt(){
        let s=''
        while (true){
            const b=await this.getByte();
            if (b==ETX)
                throw new NoMoreData();
            if (b==0x1C)
                break
            s+=String.fromCharCode(b);
        }
        return parseInt(s)
    }

    async getString(){
        let s=''
        while (true){
            let b = await this.getByte();
            if (b==ETX)
                throw new NoMoreData();
            if (b==0x1C)
                break
            s+=String.fromCharCode(b);
        }
        iconv.skipDecodeWarning = true;
        return iconv.decode(s, 'cp866')
    }

    async beep(){
        return await this.proccess(new Beep());
    }

    async pullPaper(lines){
        return await this.proccess(new PullPaper());
    }

    testConnection(){
        this.write(ENQ);
        return (this.read())==ACK;
    }

    async openShift(cashier){
        return await this.proccess(new OpenShift(cashier));
    }
    
    async closeShift(){
        return await this.proccess(new CloseShift("Васильева О.Е."));
    }
    
    async openCheck(mode){
        return await this.proccess(new OpenCheck(mode==0?2:3, 5))   // mode==0 - продажа, параметр 2- продажа, 3-возврат
    }

    async closeCheck(total, totalCard){
        // throw new Error("Принтер не готов")
        console.log(total, totalCard)
        let sum = Math.round((total?total:totalCard) * 100) / 100
        await this.proccess(new Pay(total?0:1, sum, null))
        return await this.proccess(new CloseCheck(0))
    }

    /*async pay(type, total, text){
        return await this.proccess(new Pay(type, total, text))
    }*/

    async getSerialNumber(){
        return await this.proccess(new GetInfo(1))
    }

    async nullingCheck(){
        return await this.proccess(new NullingCheck())
    }

    async income(amount, price, name, taxGroup=0, mark=null){
        if (mark){
            //await this.proccess(new AdditionalInfo(mark));
            await this.proccess(new SetKm(mark, 1, 15));
        }
        let i = new Income(amount, price, name)
        return await this.proccess(i);
    }

    async retrn(amount, price, name){
        let i = new Income(amount, price, name)
        return await this.proccess(i);
    }

    async extError(){
        return await this.proccess(new ExtError());
    }

    async getDateTime(){
        return await this.proccess(new GetDateTime());
    }

    async startWork(){
        return await this.proccess(new StartWork())
    }

    async getPosStatus(){
        return await this.proccess(new GetPosStatus())
    }

    async getExchangeStatus(nReq){
        return await this.proccess(new GetExchangeStatus(nReq));
    }

    async getDateOfFirstUnsended(){ 
        const x=await this.getExchangeStatus(0x7)
        return x.firstUnsended
    }

    async cutPaper(type){
        return await this.proccess(new CutPaper(type));
    }

    async XReport(){
        return await this.proccess(new XReport());
    }

    async isShiftOpened(){
        const {fatalState, currentFlags, documentStatus} = await this.proccess(new GetPosStatus())
        if (fatalState!=0)
            throw new Error("fatalState: "+ fatalState.toString())
        return (currentFlags & 0b00000100)?true:false
    }

    async is24HoursEnded(){
        const {fatalState, currentFlags, documentStatus} = await this.proccess(new GetPosStatus())
        if (fatalState!=0)
            throw new Error("fatalState: "+ fatalState.toString())
        return (currentFlags & 0b00001000)?true:false
    }   

    async isPaperPresent(){
        const flags =  await this.proccess(new GetPrinterStatus())
        return (flags & 0b10) == 0
    }

    async isWaitingAfterPaperOut(){
        return false
    }

    async getShiftOpeningDateTime(){
        return await this.proccess(new GetInfo(17))
    }

    async getShiftNumber(){
        const {shift} = await this.proccess(new GetCounters(1))
        return shift
    }

    async getChequeNumber(){
        const {chequeNumber} = await this.proccess(new GetCounters(2))
        return chequeNumber
    }

    async getInn(){
        return null; //s.substr(s.length-10)
    }

    async getCashTotal(){
        const {cashTotal, cardTotal} = await this.proccess(new GetCounters(3))
        const {cashReturn, cardReturn} = await this.proccess(new GetCounters(5))
        return {cashTotal, cardTotal, cashReturn, cardReturn,}
    }

    async getCashTotalX(){
        const cash = await this.proccess(new GetInfo(7))
        return cash
    }

    async getFNExpiryDate(){
        return await this.proccess(new GetInfo(14))
    }

    async checkKm(mark, mode){
        return await this.proccess(new CheckKm(mark, mode)) 
    }

    async includeKm(mode){
        return await this.proccess(new IncludeKm(mode)) 
    }

    async checkMarkDiagram(mark){
        const {result, cause, requestResult, codeProcessingRequest, statusItem} = await this.proccess(new CheckKm(mark))
        if (result & 0b00000100) { // проверка ОИСМ
            
        }
    }

}
