import {ENQ, STX, ACK, NAK} from './PosImpl.js'
import KktImpl from './PosImpl.js'


import {CommandShtrikh, Beep, OpenCheck, CloseCheck, CloseCheckV2, Income, IncomeV2, Retrn, CutPaper, PullPaper,
    XReport, OpenShift, PrintLine, CloseShift, GetPosStatus, GetShortPosStatus, CommonDumping,
    GetShiftParams, NullingCheck, CancelCheck, ContinuePrint, GetMoneyRegister, StartClosingShift, 
    ExchangeStatus, GetFNExpiryDate, GetSerialNumber, CheckKm, IncludeKm, GetTableValue,
    SetTableValue, PrintCopyOfLastCheque} from './CommandShtrikh.js'
//import { Exception } from 'sass';

export default class Shtrikh extends KktImpl{

    constructor(serial, writer, reader){
        super(serial, writer, reader);
    }

    async proccess(command){
        let ret
        /*eslint no-constant-condition: ["error", { "checkLoops": false }]*/
        while (true){
            await this.write(ENQ);
            const acknak = await this.getByte();
            if (acknak == ACK) {
                ret = await this.waitAnswer(command)
                if (ret!==null)
                    break;
            } else if (acknak == NAK){
                ret = await this.sendСommand(command)
                if (ret!==null){
                    break;
                }
            }
        }
        return ret
    }

    /*async printMultiLine(text){
        for (let currentLine of text.match(/.{1,32}/g)){
            await this.proccess(new PrintLine(currentLine))
        }

    }*/

    async waitAnswer(command){
        if (await this.getByte() != STX)
            return null;
        let len = await this.getByte();
        const data = Array();
        data.push(len);
        while (len--){
            data.push(await this.getByte());
        }
        if ((await this.getByte()) == this.xorControlSum(0, data)){
            await this.write(ACK);
            data.shift();
            return command.parseResponse(data);
        } else {
            await this.write(NAK);
        }
        return null;
    }

    async sendСommand(command){
        let i = 0
        while (i < 10) {
            await this.write(STX);
            let cmdData = command.getData()
            await this.writeData(cmdData);
            await this.write(this.xorControlSum(0, cmdData));
            if ((await this.getByte()) == ACK){
                return await this.waitAnswer(command)
            }
            i++;
        }
        return null;
    }

    //getCommand(){
    //    return new CommandShtrikh();
    //}
    async beep(){
        return await this.proccess(new Beep());
    }

    async printCopyOfLastCheque(){
        return await this.proccess(new PrintCopyOfLastCheque());
    }

    async openCheck(checkMode){
        let {mode, submode} = await this.proccess(new GetShortPosStatus())
        if (submode!=0){ 
            //await this.proccess(new ContinuePrint())
            throw new Error('В фазе печати или отсутствие чековой ленты')
        }
        await this.proccess(new OpenCheck(checkMode))
    }

    async closeCheck(total, totalCard){
        console.log("closeCheck", total, totalCard)
        let x1 = await this.proccess(new GetShiftParams())
        let x2 = await this.proccess(new GetPosStatus())
        console.log("GetShiftParams: ", x1)
        console.log("GetPosStatus", x2)
        await this.proccess(
            this.v2?(new CloseCheckV2(total, totalCard, 0)):(new CloseCheck(total, totalCard, 0)))
        return {chequeNumber: x2.chequeNumber, shift: x1.shift}
    }

    async openShift(){
        return await this.proccess(new OpenShift())
    }

    async income(amount, price, name, taxGroup=0, mark=null){
        if (mark){
            await this.proccess(new AdditionalInfo(mark));
        }
        return await this.proccess(
            this.v2?(new IncomeV2(amount, price, name, taxGroup)):new Income(amount, price, name, taxGroup));
    }

    async retrn(amount, price, name){
        return await this.proccess(new Retrn(amount, price, name));
    }

    async cutPaper(type){
        await this.proccess(new CutPaper(type));
    }

    async pay(type, total, text){
    }

    async pullPaper(lines){
        await this.proccess(new PullPaper(lines));
    }

    async nullingCheck(){
        await this.proccess(new NullingCheck())
    }

    async cancelCheck(){
        return await this.proccess(new CancelCheck())
    }

    async printLine(text){
        await this.proccess(new PrintLine(text));
    }

    async getPosStatus(){
        return await this.proccess(new GetPosStatus())
    }

    async getShortPosStatus(){
        return await this.proccess(new GetShortPosStatus());
    }

    async XReport(){
        await this.proccess(new XReport());
    }

    async closeShift(){
        return await this.proccess(new CloseShift());
    }

    //async startClosingShift(){  не понятно что за команда
    //    return await this.proccess(new StartClosingShift())
    //}

    async checkKm(mark, mode){
        return await this.proccess(new CheckKm(mark, mode)) 
    }

    async includeKm(mode){
        return await this.proccess(new IncludeKm(mode)) 
    }

    async getShiftInfo(){
        return await this.proccess(new GetShiftParams())
    }

    async getTableValue(table, row, position){
        return await this.proccess(new GetTableValue(table, row, position))
    }

    async setTableValue(table, row, position, value){
        return await this.proccess(new SetTableValue(table, row, position, value))
    }

    async isShiftOpened(){
        let {mode, submode, flags} = await this.getShortPosStatus()
        switch (mode & 0x0f){
            case 2:
            case 3:
                return true
            case 4:
                return false
        }
        return null
    }

    async is24HoursEnded(){
        let {mode, submode, flags} = await this.getShortPosStatus()
        return (mode & 0x0f) == 3
    }   

    async isPaperPresent(){
        let {mode, submode, flags} = await this.getShortPosStatus()
        //return (flags & 0b00000010) !=0
        return (flags & 0b10000000) !=0
    }

    async isWaitingAfterPaperOut(){
        let {mode, submode, flags} = await this.getShortPosStatus()
        return submode == 3 
    }

    async getSerialNumber(){
        return await this.proccess(new GetSerialNumber())
    }

    async getShiftNumber(){
        const {shift, chequeNumber} = await this.getShiftInfo()
        return shift
    }

    async getChequeNumber(){
        let x = await this.proccess(new GetPosStatus())
        return x.chequeNumber
    }
    
    async getInn(){
        let x = await this.proccess(new GetPosStatus())
        let s = "00000" + x.inn
        return s.substr(s.length-10)
    }

    async continuePrint(){
        await this.proccess(new ContinuePrint())
    }

    async getCashTotal(){
        const d0 = await this.proccess(new GetMoneyRegister(193, 0));
        const d1 = await this.proccess(new GetMoneyRegister(197, 0));
        const d2 = await this.proccess(new GetMoneyRegister(195, 0)); // возврат прихода наличными
        const d3 = await this.proccess(new GetMoneyRegister(199, 0)); // возврат прихода на карту

        console.log("Наличных: ", d0/100)
        console.log("Оплачено картой: ", d1/100)
        console.log("Возврат наличных: ", d2/100)
        console.log("Возврат на карту: ", d3/100)
        return { cashTotal: d0/100, cardTotal: d1/100, cashReturn: d2/100, cardReturn: d3/100}
    }

    async getCashTotalX(){
        const d0 = await this.proccess(new GetMoneyRegister(241, 0));
        console.log("Нал в ден.ящике: ", d0/100)
        return d0/100
    }

    async getShiftOpeningDateTime(){
        return {date: null, time: null}
    }    

    async getExchangeStatus(){
        return await this.proccess(new ExchangeStatus());
    }

    async getFNExpiryDate(){
        return await this.proccess(new GetFNExpiryDate());
    }

}
