import {Command, UnicodeToWin1251, print2106} from './Command.js'


function getErrorDescription(errorCode) {
    switch (errorCode){
        case 0x12:
            return 'Исчерпан ресурс криптографического сопроцессора'
        case 0x14:
            return 'Ресурс для хранения документов для ОФД исчерпан'
        case 0x15:
            return 'Время нахождения в очереди самого старого сообщения на выдачу более 30 календарных дней. Только для касс в режиме передачи данных.'
        case 0x16:
            return 'Продолжительность смены более 24 часов'
        case 0x35:
            return 'Некорректный параметр при данных настройках'
        case 0x45:
            return 'Cумма всех типов оплаты меньше итога чека'
        case 0x46:
            return 'Не хватает наличности в кассе'
        case 0x50:
            return 'Идет печать результатов выполнения предыдущей команды'
        case 0x58:
            return 'Ожидание команды продолжения печати'
        case 0x6b:
            return 'Нет чековой ленты'
        case 0x72:
            return 'Команда не поддерживается в данном режиме'
        case 0x73:
            return 'Команда не поддерживается в данном режиме'
        default:
            return 'Нет описания'
    }
}

class CommandShtrikh extends Command {
    constructor(){
        super();
        if (this.getCode()>0xff)
            this.push(this.getCode()>>8)
        this.push(this.getCode() & 0xff)
    }
    getData(){
        let ret = new Uint8Array(this.pushIndex + 1);
        ret[0] = this.pushIndex;
        for (let i=0;i<=this.pushIndex;i++)
            ret[i+1] = this.data[i];
        return ret;
    }
    getStr(data){
        let ret = '';
        let i=0
        while (data.length){
            let code=data.shift()
            if (code==0)
                break;
            ret+=String.fromCharCode(code)
        }
        return ret;
    }
    getUInt8(data){
        let ret = new Uint8Array(240);
        let i=0
        while (data.length){
            ret[i++]=data.shift()
        }
        return ret.slice(0,i);
    }
    addAdminPassword(){
        this.push(0x1e);
        this.push(0x00);
        this.push(0x00);
        this.push(0x00);
    }
    addCashierPassword(){
        this.push(0x01);
        this.push(0x00);
        this.push(0x00);
        this.push(0x00);
    }
    push5byte(x){
        this.push(x & 0xff);
        this.push((x>>8) & 0xff);
        this.push((x>>16) & 0xff);
        this.push((x>>24) & 0xff);
        this.push(0x00);
    }
    push6byte(x){
        this.push(Number(x & BigInt(0xff)));
        this.push(Number((x>>BigInt(8)) & BigInt(0xff)));
        this.push(Number((x>>BigInt(16)) & BigInt(0xff)));
        this.push(Number((x>>BigInt(24)) & BigInt(0xff)));
        this.push(Number((x>>BigInt(32)) & BigInt(0xff)));
        this.push(Number((x>>BigInt(40)) & BigInt(0xff)));
    }

    parseResponseStart(data, parseError=true, parseCashierNumber=true){
        console.log('------- Command -->', this.getCode().toString(16)+'h', ', <--' + ((this.getCode()>0xff) ? (data.shift()<<8 | data.shift()) : data.shift()).toString(16) + 'h');
        if (parseError){
            this.errorCode = data.shift()
            if (this.errorCode)
                console.log('Error code: ' + this.errorCode.toString(16) + 'h');
            if (this.errorCode){
                throw new Error('Command code:' + this.getCode().toString(16) + "h, error code:"+ this.getErrorCode().toString(16)+'h\n'+getErrorDescription(this.getErrorCode()))
            }
        }
        if (parseCashierNumber==true){
            let cn = data.shift()
            //console.log("Cashier number:", cn);
        }
    }

    parse2ByteFlags(data){
        const value = data.shift() | data.shift()<<8
        console.log("Flags: " + value.toString(2) + 'b');
        return value
    }

    parseResponseFinish(data){
        if (data.length)
            console.log("Other:", data);
    }

    parseResponse(data){
        this.parseResponseStart(data)
        this.parseResponseFinish(data)
        return {}
    }
}

class CommandCashier extends CommandShtrikh{
    constructor(){
        super();
        this.addCashierPassword();
    }
}

class CommandAdmin extends CommandShtrikh{
    constructor(){
        super();
        this.addAdminPassword();
    }
}

class PrintLine extends CommandAdmin{
    getCode(){return 0x17}
    constructor(text, bold=false, alignRight=false){
        super(bold ? 0x12 : 0x17)
        this.push(0b01000010);
        if (alignRight)
            text = text.padStart(32)
        Array.from(UnicodeToWin1251(text)).forEach(e => {
            this.push(e.charCodeAt(0));
        });
    }
}

class OpenCheck extends CommandCashier{
    getCode(){return 0x8d}
    constructor(type){
        super()
        this.push(type)
    }
}

class ContinuePrint extends CommandCashier{
    getCode(){return 0xb0}
}

class CancelCheck extends CommandAdmin{
    getCode(){return 0xff08}
}

class NullingCheck extends CommandCashier{
    getCode(){return 0x88}
}

class Income extends CommandCashier{
    getCode(){return 0x80}
    constructor(amount, price, name, taxGroup=0){
        super();
        this.push5byte(parseInt(Math.round(amount*1000)));
        this.push5byte(parseInt(Math.round(price*100)));
        this.push(0); // otdel
        this.push(taxGroup); // nalog1
        this.push(taxGroup);
        this.push(taxGroup);
        this.push(taxGroup);
        let xname=name.substr(0,100).padEnd(40)
        Array.from(UnicodeToWin1251(xname)).forEach(e => {
            this.push(e.charCodeAt(0));
        });
    }
}

class IncomeV2 extends CommandCashier{
    getCode(){return 0xff46}
    constructor(amount, price, name, taxGroup=0){
        super();
        this.push(1); // тип. 1- приход
        const bigAmount = BigInt(parseInt(Math.round(amount*1000000)))
        this.push6byte(bigAmount);
        this.push5byte(parseInt(Math.round(price*100)));
        this.push(0xff);this.push(0xff);this.push(0xff);this.push(0xff);this.push(0xff); // сумма операций
        this.push(0xff);this.push(0xff);this.push(0xff);this.push(0xff);this.push(0xff); // налог
        this.push(8); // налоговая ставка
        this.push(0); // отдел
        this.push(4); // признак способа расчета. 4 - полный расчет
        this.push(1); // признак предмета расчета. 1 - товар
        let xname=name.substr(0,100).padEnd(40)
        Array.from(UnicodeToWin1251(xname)).forEach(e => {
            this.push(e.charCodeAt(0));
        });
    }
    parseResponse(data){
        this.parseResponseStart(data, true, false)
        this.parseResponseFinish(data)
    }
}

class Retrn extends CommandCashier{
    getCode(){return 0x82}
    constructor(amount, price, name){
        super();
        this.push5byte(parseInt(Math.round(amount*1000)));
        this.push5byte(parseInt(Math.round(price*100)));
        this.push(0); // otdel
        this.push(0); // nalog1
        this.push(0);
        this.push(0);
        this.push(0);
        let xname=name.substr(0,100).padEnd(40)
        Array.from(UnicodeToWin1251(xname)).forEach(e => {
            this.push(e.charCodeAt(0));
        });
    }
}

class CloseCheck extends CommandCashier{
    getCode(){return 0x85}
    constructor(cash, card=0, debt=0){
        super();

        this.push5byte(parseInt(Math.round(cash * 100)));
        this.push5byte(parseInt(Math.round(card * 100)));
        this.push5byte(parseInt(Math.round(debt * 100)));
        this.push5byte(0);

        this.push(0x00); // discount
        this.push(0x00);

        this.push(0x00); // nalog
        this.push(0x00);
        this.push(0x00);
        this.push(0x00);

        for (let i=0;i<=39;i++)
            this.push(0x00);
    }
    parseResponse(data){
        console.log("parseResponse CloseCheck");
        this.parseResponseStart(data);
        console.log("sdacha:", data.shift());
        console.log("sdacha:", data.shift());
        console.log("sdacha:", data.shift());
        console.log("sdacha:", data.shift());
        console.log("sdacha:", data.shift());
        this.parseResponseFinish(data)
        return {}
    }
}

class CloseCheckV2 extends CommandCashier{
    getCode(){return 0xff45}
    constructor(cash, card=0, debt=0){
        super();
        this.push5byte(parseInt(Math.round(cash * 100)));
        this.push5byte(parseInt(Math.round(card * 100)));
        this.push5byte(0);
        this.push5byte(0);
        this.push5byte(0);
        this.push5byte(0);
        this.push5byte(0);
        this.push5byte(0);
        this.push5byte(0);
        this.push5byte(0);
        this.push5byte(0);
        this.push5byte(0);
        this.push5byte(0);
        this.push5byte(0);
        this.push5byte(0);
        this.push5byte(0);
        this.push(0x00);  // округление до рубля
        this.push(0x00);

        this.push5byte(0);  // налоги
        this.push5byte(0);
        this.push5byte(0);
        this.push5byte(0);
        this.push5byte(0);
        this.push5byte(0);

        this.push(0x01); // сист. налогообложения  Бит 0 – ОСН Бит 1 – УСН доход Бит 2 – УСН доход минус расход Бит 3 – ЕНВД Бит 4 – ЕСП Бит 5 – ПСН
    }
    parseResponse(data){
        console.log("parseResponse CloseCheck");
        this.parseResponseStart(data, true, false);
        console.log("sdacha:", data.shift());
        console.log("sdacha:", data.shift());
        console.log("sdacha:", data.shift());
        console.log("sdacha:", data.shift());
        console.log("sdacha:", data.shift());
        console.log("номер чека:", data.shift());
        console.log("номер чека:", data.shift());
        console.log("номер чека:", data.shift());
        console.log("номер чека:", data.shift());
        console.log("номер чека:", data.shift());
        console.log("1?:", data.shift());
        console.log("2?:", data.shift());
        console.log("3?:", data.shift());
        this.parseResponseFinish(data)
        return {}
    }
}

class CutPaper extends CommandCashier{
    getCode(){return 0x25}
    constructor(type){
        super();
        this.push(type);
    }
}

class XReport extends CommandAdmin{
    getCode(){return 0x40}
}

class OpenShift extends CommandCashier{
    getCode(){return 0xe0}
    parseResponse(data){
        this.parseResponseStart(data, true)
        this.parseResponseFinish(data)
        return {}
    }
}

class StartOpeningShift extends CommandAdmin{
    getCode(){return 0xff41}
}

class StartClosingShift extends CommandAdmin{
    getCode(){return 0xff42}
}

class ExchangeStatus extends CommandAdmin{
    getCode(){return 0xff39}
    parseResponse(data){
        this.parseResponseStart(data, true, false)
        console.log("status:", data.shift());
        console.log("Reading state:", data.shift());
        let b1=data.shift()
        let b2=data.shift()
        console.log("Amount messages to ofd:", b2<<8 + b1);
        console.log("Number of first unsend for OFD:", data.shift()<<24 + data.shift()<<16 + data.shift()<<8 + data.shift());
        const year = data.shift()
        const month = data.shift()
        const day = data.shift()
        const hour = data.shift()
        const minute = data.shift()
        const s1 = '20'+year+'/'+month+'/'+day+' '+hour+':'+minute
        console.log("Date of first in queue: ", s1);
        this.parseResponseFinish(data)
        return {firstUnsended: (year==0 && month==0 && day==0)?null:new Date(s1)}
    }
}

class GetFNExpiryDate extends CommandAdmin{
    getCode(){return 0xff03}
    parseResponse(data){
        this.parseResponseStart(data, true, false)
        const year = data.shift()
        const month = data.shift()
        const day = data.shift()
        const s1 = '20'+year+'/'+month+'/'+day;
        console.log("Date of expiry FN: ", s1);
        this.parseResponseFinish(data)
        return {expiryDate: (year==0 && month==0 && day==0)?null:new Date(s1)}
    }
}

class StartClosingFiscaleMode extends CommandAdmin{
    getCode(){return 0xff3d}
}

class CloseFiscaleMode extends CommandAdmin{
    getCode(){return 0xff3e}
    parseResponse(data){
        this.parseResponseStart(data)
        console.log("FD number:", data.shift()<<24 + data.shift()<<16 + data.shift()<<8 + data.shift());
        console.log("Fiscal priznak:", data.shift()<<24 + data.shift()<<16 + data.shift()<<8 + data.shift());
        this.parseResponseFinish(data)
        return {}
    }
}

class CloseShift extends CommandAdmin{
    getCode(){return 0x41}
}

class CommonDumping extends CommandAdmin{
    getCode(){return 0x27}
}

class CommandWithState extends CommandCashier{
    getRegimeDescription(regime){
        switch (regime){
            case 1:
                return 'Выдача данных'
            case 2:
                return 'Открытая смена, 24 часа не кончились'
            case 3:
                return 'Открытая смена, 24 часа кончились'
            case 4:
                return 'Закрытая смена'
            case 5:
                return 'Блокировка по неправильному паролю налогового инспектора'
            case 6:
                return 'Ожидание подтверждения ввода даты'
            case 7:
                return 'Разрешение изменения положения десятичной точки'
            case 8:
                return 'Открытый документ'
            case 9:
                return 'Режим разрешения технологического обнуления'
            case 10:
                return 'Тестовый прогон'
            case 11:
                return 'Печать полного фискального отчета'
            case 12:
                return 'Зарезервировано'
            case 13:
                return 'Работа с фискальным подкладным документом'
            case 14:
                return 'Печать подкладного документа'
            case 15:
                return 'Фискальный подкладной документ сформирован'
        }
    }

    getSubRegimeDescription(subRegime){
        switch (subRegime){
            case 0: return "Бумага есть – ККТ не в фазе печати операции – может принимать от хоста команды, связанные с печатью на том документе, датчик которого сообщает о наличии бумаги."
            case 1: return "Пассивное отсутствие бумаги – ККТ не в фазе печати операции – не принимает от хоста команды, связанные с печатью на том документе, датчик которого сообщает об отсутствии бумаги."
            case 2: return "Активное отсутствие бумаги – ККТ в фазе печати операции – принимает только команды, не связанные с печатью. Переход из этого подрежима только в подрежим 3"
            case 3: return "После активного отсутствия бумаги – ККТ ждет команду продолжения печати. Кроме этого принимает команды, не связанные с печатью."
            case 4: return "Фаза печати операции полных фискальных отчетов 1 – ККТ не принимает от хоста команды, связанные с печатью, кроме команды прерывания печати."
            case 5: return "Фаза печати операции – ККТ не принимает от хоста команды, связанные с печатью."
        }
    }
}

class GetShortPosStatus extends CommandWithState{
    getCode(){return 0x10}
    parseResponse(data){
        this.parseResponseStart(data);
        const flags = this.parse2ByteFlags(data)
        let mode = data.shift();
        let regime = mode&0b00001111
        let regimeStatus = mode>>4
        console.log("Режим и статус режима:", regime, " (",this.getRegimeDescription(regime), ") ", regimeStatus);
        let submode = data.shift()
        console.log("Подрежим:", submode, " (", this.getSubRegimeDescription(submode),")");
        let ops_count = data.shift()
        const battaryV = data.shift()
        const powerSupplyV = data.shift()
        const reserved = data.shift() | data.shift()<<8
        const opsInCheck = (data.shift()<<8) | ops_count
        const reserved2 = data.shift()
        const reserved3 = data.shift()
        const reserved4 = data.shift()
        const lastPrint = data.shift()
        this.parseResponseFinish(data)
        return {mode: mode, submode: submode, flags: flags}
    }
}

class GetPosStatus extends CommandWithState{
    getCode(){return 0x11}
    constructor(){
        super();
        this.state=null;
        this.stateStatus=null;
        this.subState=null;
    }
    parseResponse(data){
        this.parseResponseStart(data);
        console.log("Pos software version:", String.fromCharCode(data.shift()) + '.' + String.fromCharCode(data.shift()));
        console.log("Pos software build:", data.shift() | data.shift()<<8);
        console.log("Pos software date:", data.shift() + '.' + data.shift() + '.' + data.shift());
        console.log("Number in room:", data.shift());
        let chequeNumber = data.shift() | data.shift()<<8
        console.log("Document number:", chequeNumber); 
        const flags = this.parse2ByteFlags(data)
        let mode=data.shift();
        this.state = mode&0b00001111
        this.stateStatus = mode>>4
        this.subState = data.shift()
        console.log("Режим и статус режима:", this.state, " (",this.getRegimeDescription(this.state), ") ", this.stateStatus);
        console.log("Подрежим:", this.subState, " (", this.getSubRegimeDescription(this.subState),")");
        console.log("Номер порта:", data.shift());
        data.shift(); data.shift(); data.shift(); data.shift(); data.shift(); data.shift(); data.shift();
        console.log("Pos date:", data.shift() + '.' + data.shift() + '.' + data.shift());
        console.log("Pos time:", data.shift() + ':' + data.shift() + ':' + data.shift());
        data.shift();
        console.log("Заводской номер:", data.shift().toString(16) + data.shift().toString(16) + data.shift().toString(16) + data.shift().toString(16) + 'h');
        const lastClosedShift = data.shift() | data.shift()<<8
        console.log("Последняя закрытая смена:", lastClosedShift); 
        data.shift();
        data.shift();
        console.log("Количество перерегистраций:", data.shift()); 
        console.log("Количество оставшихся перерегистраций:", data.shift()); 
        //console.log(data.shift().toString(16));
        //console.log(data.shift().toString(16));
        //console.log(data.shift().toString(16));
        //console.log(data.shift().toString(16));
        //console.log(data.shift().toString(16));
        //console.log(data.shift().toString(16));
        let inn = BigInt(data.shift() | data.shift()<<8 | data.shift()<<16  | data.shift()<<24)
        let inn2 = BigInt(data.shift() | data.shift()<<8)
        let innStr = (inn+inn2*BigInt(0xffffffff+1)).toString()
        console.log("ИНН:", innStr);
        //console.log(data.shift());
        //console.log(data.shift());
        this.parseResponseFinish(data)
        return {chequeNumber: chequeNumber, lastClosedShift: lastClosedShift, mode: mode, inn: innStr, flags: flags}
    }
}

class GetShiftParams extends CommandAdmin{
    getCode(){return 0xff40}
    parseResponse(data){
        this.parseResponseStart(data, true, false)
        console.log("Shift state:", data.shift());
        const shift = data.shift() | (data.shift()<<8)
        const chequeNumber = data.shift() | (data.shift()<<8)
        console.log("Shift number:", shift);
        console.log("Cheque number:", chequeNumber);
        this.parseResponseFinish(data)
        return {shift: shift, chequeNumber: chequeNumber}
    }
}

class CheckKm extends CommandCashier{
    getCode(){return 0xff61}
    constructor(mark, mode){
        super()
        this.push(1)  // Планируемый статус товара(тег 2003)    1. Штучный товар реализован
        this.push(0)  // Режим обработки  Тег 2102 (сейчас всегда «0»)
        this.push(mark.length)  // посчитать длинну км
        this.push(0)  // посчитать длинну списка TLV   (нет)

        //this.pushString(this.formatMark(mark))
        Array.from(mark).forEach(e => {
            this.push(e.charCodeAt(0));
        });
    }
    parseResponse(data){
        this.parseResponseStart(data, true, false)
        const tag2004 = data.shift()
        const reasonWhyNotCheckedLocal = data.shift()
        const recognizedKmType = data.shift()
        const additionalParamsLen = data.shift()
        const codeResponse = data.shift()
        const result2106 = 0
        const notError=true
        if (notError){
            result2106 = data.shift()
            print2106(result2106)
            //tlvRespone = parseTlv()
        }

        this.parseResponseFinish(data)
        return {}
    }
}

class IncludeKm extends CommandCashier{
    getCode(){return 0xff69}
    constructor(mode){
        super()
        this.push(mode) // 0 -  исключить, 1 - включить
    }
    parseResponse(data){
        this.parseResponseStart(data, true, false)
        const result0 = data.shift()
        const result1 = data.shift()
        const result2 = data.shift()
        const result3 = data.shift()
        const result4 = data.shift()
        const result5 = data.shift()
        print2106(result5)
        this.parseResponseFinish(data)
        return result
    }
}

class SetKm extends CommandCashier{
    getCode(){return 0xff67}
    constructor(mark){
        super()
        this.push(0)  // посчитать длинну км
        //this.pushString(this.formatMark(mark))
        Array.from(text).forEach(e => {
            this.push(e.charCodeAt(0));
        });

        //this.push5byte(parseInt(Math.round(amount*1000)));
        //this.push5byte(parseInt(Math.round(price*100)));
        //this.push(0); // otdel

    }
    parseResponse(data){
        this.parseResponseStart(data, true, false)
        const result = data.shift()
        print2106(result)
        this.parseResponseFinish(data)
        return result
    }
}

class Beep extends CommandCashier{
    getCode(){return 0x13}
}


/*
class CutPaper(Command):
    def __init__(self, type):
        super().__init__()
        self.data.append(0x25)
        self.add_admin_password()
        self.data += type.to_bytes(1, byteorder="big")
*/

class PullPaper extends CommandCashier{
    getCode(){return 0x29}
    constructor(linesCount){
        super();
        this.push(0b00000010);
        this.push(linesCount);
    }
}


class PrintCopyOfLastCheque extends CommandCashier{
    getCode(){return 0x8c}
}


class SetTableValue extends CommandAdmin{
    getCode(){return 0x1e}
    constructor(table, row, field, value){
        super()
        this.push(table)
        this.push(row&0xff);this.push((row>>8)&0xff)
        this.push(field&0xff)
        for (let i=0;i<=value.length;i++)
            this.push(value[i]&0xff);
    }
    parseResponse(data){
        this.parseResponseStart(data, true, false)
        this.parseResponseFinish(data)
    }
}

class GetTableValue extends CommandAdmin{
    getCode(){return 0x1f}
    constructor(table, row, field){
        super()
        this.push(table)
        this.push(row&0xff);this.push((row>>8)&0xff)
        this.push(field&0xff)
    }
    parseResponse(data){
        this.parseResponseStart(data, true, false)
        let value = this.getUInt8(data)
        this.parseResponseFinish(data)
        return value
    }
}

class GetSerialNumber extends GetTableValue{
    constructor(){
        super(0x12, 0x0001, 0x01)
    }

    parseResponse(data){
        return new TextDecoder().decode(super.parseResponse(data).slice(0,16))
    }
}

class GetMoneyRegister extends CommandCashier{
    getCode(){return 0x1a}
    constructor(FReg, KReg){
        super()
        this.push(FReg)
        //this.push(KReg)
    }
    parseResponse(data){
        this.parseResponseStart(data)
        const d = data.shift() | data.shift()<<8 | data.shift()<<16 | data.shift()<<24 | data.shift()<<32 | data.shift()<<40
        console.log("value: ", d);
        //const shift = data.shift() | (data.shift()<<8)
        //const chequeNumber = data.shift() | (data.shift()<<8)
        //console.log("Shift number:", shift);
        //console.log("Cheque number:", chequeNumber);
        this.parseResponseFinish(data)
        return d
    }
}



export {Beep, OpenCheck, CloseCheck, CloseCheckV2, CommandShtrikh, Income, IncomeV2, Retrn, CutPaper, XReport, OpenShift, PrintLine, CloseShift, 
    GetPosStatus, GetShortPosStatus, CommonDumping, PullPaper, GetShiftParams, NullingCheck, CancelCheck, ContinuePrint,
    GetMoneyRegister, StartClosingShift, ExchangeStatus, GetFNExpiryDate, GetSerialNumber, CheckKm, IncludeKm, GetTableValue, SetTableValue,
    PrintCopyOfLastCheque}