import {Command, print2106,} from './Command.js'
import {ENQ, ETX, ACK} from './PosImpl.js'
import {UnintendedData} from './VikiPrint.js'

//var iconv = require('iconv-lite');
import iconv from "iconv-lite" 
import {format, parse} from 'date-fns'


class CommandViki extends Command {
    constructor(){
        super();
        this.pushTwoCharHex(this.getCode())
    }
    async readAnswer(posImpl){ // check no data
        /*let b = await posImpl.getByte();
        if (b!=ETX){
            console.log("UnintendedData", b)
            throw new UnintendedData()
        }*/
    }

    getData(){
        return this.data.subarray(0, this.pushIndex)
    }

    pushTwoCharHex(code){
        this.push((code >>4).toString(16).toUpperCase().charCodeAt(0))
        this.push((code & 0x0f).toString(16).toUpperCase().charCodeAt(0))
    }
    
    pushInt(value){
        const utf8Encode = new TextEncoder()
        utf8Encode.encode(value.toString()).forEach(e=>{this.push(e)}); 
        this.push(0x1C)
    }
    pushDecimal(value){
        const utf8Encode = new TextEncoder()
        utf8Encode.encode(value.toString()).forEach(e=>{this.push(e)}); 
        this.push(0x1C)
    }
    pushString(value){
        if (value){
            const utf8Encode = new TextEncoder()
            utf8Encode.encode(value).forEach(e=>{this.push(e)}); 
        }
        this.push(0x1C)
    }
    pushString866(value){
        if (value){
            //const utf8Encode = new TextEncoder()
            //utf8Encode.encode(value).forEach(e=>{this.push(e)}); 
            iconv.encode(value, 'cp866').forEach(e=>{this.push(e)})
        }
        this.push(0x1C)
    }
}


class Beep extends CommandViki{
    constructor(type){
        super();
        this.pushInt(150)
    }
    getCode(){return 0x82}
}



class GetCounters extends CommandViki{
    getCode(){return 0x01}
    constructor(nReq){
        super();
        //this.nReq = nReq
        this.pushInt(nReq)
    }
    async readAnswer(posImpl){
        try{
            const nReq = await posImpl.getInt()
            if (nReq==1){
                const shift = await posImpl.getInt()
                console.log("Номер смены: ", shift)
                return { shift: shift, }
            }
            else if (nReq==2){
                const chequeNumber = await posImpl.getInt()
                console.log("Номер чека: ", chequeNumber)
                return { chequeNumber: chequeNumber, }
            }
            else if (nReq==3){
                const d0 = await posImpl.getString()
                const d1 = await posImpl.getString()
                const d2 = await posImpl.getString()
                const d3 = await posImpl.getString()
                const d4 = await posImpl.getString()
                const d5 = await posImpl.getString()
                const d6 = await posImpl.getString()
                const d7 = await posImpl.getString()
                const d8 = await posImpl.getString()
                const d9 = await posImpl.getString()
                const d10 = await posImpl.getString()
                const d11 = await posImpl.getString()
                const d12 = await posImpl.getString()
                const d13 = await posImpl.getString()
                const d14 = await posImpl.getString()
                const d15 = await posImpl.getString()
                console.log("Наличных: ", d0)
                console.log("Оплачено картой: ", d1)
                return { cashTotal: d0, cardTotal: d1,}
            } else if (nReq==5){
                const d0 = await posImpl.getString()
                const d1 = await posImpl.getString()
                const d2 = await posImpl.getString()
                const d3 = await posImpl.getString()
                const d4 = await posImpl.getString()
                const d5 = await posImpl.getString()
                const d6 = await posImpl.getString()
                const d7 = await posImpl.getString()
                const d8 = await posImpl.getString()
                const d9 = await posImpl.getString()
                const d10 = await posImpl.getString()
                const d11 = await posImpl.getString()
                const d12 = await posImpl.getString()
                const d13 = await posImpl.getString()
                const d14 = await posImpl.getString()
                const d15 = await posImpl.getString()
                console.log("Возврат: ", d0)
                console.log("Возврат на карту: ", d1)
                return { cashReturn: d0, cardReturn: d1,}
            }

            
        } catch (NoMoreData){
            console.log("no more data")
        }
    }
}


class Pay extends CommandViki{
    getCode(){return 0x47}
    constructor(type, value, text){
        super();
        this.pushInt(type) // тип платежа (0 - наличные)
        this.pushDecimal(value) // сумма
        this.pushString(text)
    }
}


class GetInfo extends CommandViki{
    getCode(){return 0x02}
    constructor(type){
        super();
        this.pushInt(type) // тип платежа (0 - наличные)
    }
    async readAnswer(posImpl){
        try{
            const nReq = await posImpl.getInt()
            if (nReq==1){
                return await posImpl.getString() // KKT serial number
            } else if (nReq==7){
                return await posImpl.getString()
            } else if (nReq==14){
                const sdate = await posImpl.getString() 
                return {expiryDate: parse(sdate, "ddMMyy", new Date()),}
            } else if (nReq==17){
                const sdate = await posImpl.getString() 
                const stime = await posImpl.getString()
                return {date: parse(sdate+stime, "ddMMyyHHmmss", new Date()),}
            }
        } catch (NoMoreData){
            console.log("no more data")
        }
    }
}


class GetPrinterStatus extends CommandViki{
    getCode(){return 0x04}
    async readAnswer(posImpl){
        try{
            const flags = await posImpl.getInt()
            return flags
        } catch (NoMoreData){
            console.log("no more data")
        }
    }
}


class GetPosStatus extends CommandViki{
    getCode(){return 0x00}
    constructor(){
        super();
    }
    async readAnswer(posImpl){
        try{
            const fatalState = await posImpl.getInt()
            console.log("fatalState: ", fatalState)
            const currentFlags = await posImpl.getInt()
            console.log("currentFlags: ", currentFlags.toString(2)+'b')
            if (currentFlags & 0b1)
                console.log("\tНе выполнена команда “Начало работы”")
            if (currentFlags & 0b10)
                console.log("\tНефискальный режим")
            if (currentFlags & 0b100)
                console.log("\tСмена открыта")
            if (currentFlags & 0b1000)
                console.log("\tСмена больше 24 часов")
            if (currentFlags & 0b10000)
                console.log("\tАрхив ФН закрыт")
            if (currentFlags & 0b100000)
                console.log("\tФН не зарегистрирован")
            if (currentFlags & 0b1000000)
                console.log("\tЗарезервировано")
            if (currentFlags & 0b10000000)
                console.log("\tЗарезервировано")
            if (currentFlags & 0b100000000)
                console.log("\tНе было завершено закрытие смены, необходимо повторить операцию")
            if (currentFlags & 0b1000000000)
                console.log("\tОшибка контрольной ленты")
            const documentStatus = await posImpl.getInt()
            console.log("Статус документа, тип текущего открытого документа: ")
            switch (documentStatus&0xf){
                case 0:
                    console.log("   Документ закрыт"); 
                    break
                case 1:
                    console.log("   Сервисный документ"); 
                    break
                case 2:
                    console.log("   чек на продажу (приход)");
                    break
                case 3:
                    console.log("   чек на возврат (возврат прихода)");
                    break
                case 4:
                    console.log("   внесение в кассу");
                    break
                case 5:
                    console.log("   инкассация");
                    break
                case 6:
                    console.log("   чек на покупку (расход)");
                    break
                case 7:
                    console.log("   чек на возврат покупки (возврат расхода)");
                    break
                default:
                    console.log("   unknown", documentStatus&0xf);
                    break
            }

            console.log("   состояние документа: ", )
            switch (documentStatus>>4){
                case 0:
                    console.log("   Документ закрыт"); 
                    break
                case 1:
                    console.log("   устанавливается после команды \"открыть документ\" (Для типов документа 2 и 3 можно добавлять товарные позиции)"); 
                    break
                case 2:
                    console.log("   Устанавливается после первой команды \"Подытог\"");
                    break
                case 3:
                    console.log("   Устанавливается после второй команды \"Подытог\" или после начала команды \"Оплата\" (Можно только производить оплату различными типами платежных средств)");
                    break
                case 4:
                    console.log("   Расчет завершен, требуется закрыть документ");
                    break
                case 8:
                    console.log("   Документ закрыт в ФН, но чек не допечатан. Аннулирование документа невозможно, необходимо еще раз выполнить команду закрытия документа");
                    break
                default:
                    console.log("   unknown", documentStatus>>4);
                    break
            }
            return {
                fatalState: fatalState,
                currentFlags: currentFlags,
                documentStatus: documentStatus,
            }
        } catch (NoMoreData){
            console.log("no more data")
        }
    }
}


class GetExchangeStatus extends CommandViki{
    getCode(){return 0x78}
    constructor(nReq){
        super();
        this.pushInt(nReq)
    }

    async readAnswer(posImpl){
        try{
            const nReq = await posImpl.getInt()
            if (nReq==7){
                const x = await posImpl.getInt()
                const y = await posImpl.getInt()
                const z = await posImpl.getInt()
                const sdate = await posImpl.getString() 
                let nb = await posImpl.checkNextByte()
                let stime = (nb==ETX)?"000000":(await posImpl.getString())  // нет времени в некоторых прошивках. проверим на конец данных
                if (sdate=="000000")
                    return {firstUnsended: null}
                return {firstUnsended: parse(sdate+stime, "ddMMyyHHmmss", new Date()),}
            }
            throw new Error('Unhandled request');
        } catch (NoMoreData){
            console.log("no more data")
        }
    }
}


class TestConnection extends CommandViki{
    getCode(){ return ENQ; }
}

class OpenShift extends CommandViki{
    getCode(){return 0x23}
    constructor(cashier){
        super();
        this.pushString(cashier);
    }
}

class CloseShift extends CommandViki{
    getCode(){return 0x21}
    constructor(cashier){
        super();
        this.pushString(cashier);
        this.pushInt(0);
        this.pushString(null)  //  ФФД 1.2
        this.pushString(null)  //  ФФД 1.2
    }
}

class PullPaper extends CommandViki{
    getCode(){return 0x0A}
    constructor(linesCount){
        super();
    }
}

class CutPaper extends CommandViki{
    getCode(){return 0x34}
    constructor(mode){
        super();
    }
}


class OpenCheck extends CommandViki{
    getCode(){return 0x30}
    constructor(mode, taxSystem, docNumber=1){
        super();
        this.pushInt(mode); // тип. 2 - продажа (приход)
        this.pushInt(1); // отдел
        this.pushString('1234')  // имя оператора
        this.pushInt(docNumber); // номер документа
        this.pushInt(2); // система налогообложения 0..5. 0-ндс, 1-усн-доход, 2-усн-доход-расход
        //utf8Encode.encode('NA').forEach(e=>{data.push(e)}); // адрес
    }
}

class CloseCheck extends CommandViki{
    getCode(){return 0x31}
    constructor(mode){
        super();
        this.pushInt(1)  // отрезка чека
        this.pushString(null) // Адрес покупателя
        this.pushInt(0)
        this.pushString(null) // Место расчетов
    }
    async readAnswer(posImpl){
        try{
            let num = await posImpl.getInt()
            console.log("сквозной номер: ", num)
            let opCounter = await posImpl.getString()
            console.log("Операционный счетчик: ", opCounter)
            let fdfp = await posImpl.getString()
            console.log("Строка ФД и ФП: ", fdfp)
            let fd = await posImpl.getInt()
            console.log("ФД: ", fd)
            let fp = await posImpl.getInt()
            console.log("ФП: ", fp)
            let shift = await posImpl.getInt()
            console.log("Номер смены: ", shift)
            let numInShift = await posImpl.getInt()
            console.log("Номер документа в смене: ", numInShift)
            let date = await posImpl.getString()
            console.log("Дата документа: ", date)
            let time = await posImpl.getString()
            console.log("Время документа: ", time)
            return {shift: shift, chequeNumber: num}
        } catch (NoMoreData){
            console.log("no more data")
        }
    }
}

class Income extends CommandViki{
    getCode(){return 0x42}
    constructor(amount, price, name, taxGroup=0){
        super();
        //let s=iconv.encode(name, 'cp866')
        //s.forEach(e=>{this.push(e)})
        this.pushString866(name)
        this.pushString(null) // артикул
        this.pushDecimal(amount) // кол-во
        this.pushDecimal(price) // цена
    }
    async readAnswer(posImpl){
        try{
            let tax = await posImpl.getString()
            console.log("tax: ", tax)
        } catch (NoMoreData){
            console.log("no more data")
        }
    }
}

class AdditionalInfo extends CommandViki{
    getCode(){return 0x24}
    constructor(mark){
        super();
        this.pushString("") // марка
        this.pushString("")
        this.pushString("")
        this.pushString("")
        this.pushString("")
        this.pushString("")
        this.pushString("")
        this.pushString("")
        this.pushString("")
        this.pushString("")
        this.pushString("")
        this.pushString("")
        this.pushString("")
        this.pushString("")
        this.pushString("")
        this.pushString("")
        this.pushString("")
        this.pushString("")
        this.pushString("")
    }
    async readAnswer(posImpl){
        try{
            let result = await posImpl.getString()
            console.log("result: ", result)
        } catch (NoMoreData){
            console.log("no more data")
        }
    }
}


const tag2108_picece = 0;
class Marks extends CommandViki{
    getCode(){return 0x79}
    constructor(mark){
        super();
        this.pushInt(this.getType())
    }
    formatMark(mark){
        return mark.replaceAll("\x1D","$1D")
    }
    async readAnswer(posImpl){
        try{
            const type = await posImpl.getInt()
            console.log(" Код подзапроса: ", type)
        } catch (NoMoreData){
            console.log("no more data")
        }
    }
}

class CheckKm extends Marks{
    getType() {return 1}
    constructor(mark, mode){
        super()
        this.pushString(this.formatMark(mark))
        this.pushInt(0)  // Режим обработки кода маркировки = 0
        this.pushInt(1)  // Планируемый статус товара(тег 2003)
        this.pushInt(1)  // Количество товара (тег 1023)  DECIMAL
        this.pushInt(tag2108_picece)  // Мера количества (тег 2108) 
        this.pushInt(mode)  // Режим работы 
    }
    async readAnswer(posImpl){
        try{
            await super.readAnswer(posImpl)
            const result = await posImpl.getInt()  // Результат проверки КМ в ФН (тег 2106)  М М- М+
            print2106(result)

            const cause = await posImpl.getInt()  // Причина того, что КМ не проверен в ФН
            console.log('Причина того, что КМ не проверен в ФН')
            switch (cause){
                case 0:
                    console.log('   КМ проверен в ФН')
                    break;
                case 1:
                    console.log('   КМ данного типа не подлежит проверке в ФН')
                    break;
                case 2:
                    console.log('   ФН не содержит ключ проверки кода проверки этого КМ')
                    break;
                case 3:
                    console.log('   Проверка невозможна, так как отсутствуют теги 91 и/или 92 или их формат неверный')
                    break;
                case 4:
                    console.log('   Внутренняя ошибка в ФН при проверке этого КМ')
                    break;
            }
            const requestResult = await posImpl.getInt()  // Результаты обработки запроса (тег 2005)
            console.log('Результаты обработки запроса (тег 2005)')
            if (requestResult & 0b00000010)
                console.log('   результат проверки КП КМ положительный')
            else
                console.log('   результат проверки КП КМ отрицательный')
            if (requestResult & 0b00001000)
                console.log('   статус товара корректен (если реквизит "ответ ОИСМ о статусе товара" (тег 2109) принимает значение "1")')
            else
                console.log('   статус товара некорректен (если реквизит "ответ ОИСМ о статусе товара" (тег 2109) принимает значение "2" или "3")')

            const codeProcessingRequest = await posImpl.getInt()  // Код обработки запроса (тег 2105)
            console.log('Причина того, что КМ не проверен в ФН')
            switch (codeProcessingRequest){
                case 0:
                    console.log('   Запрос имеет корректный формат, в том числе корректный формат кода маркировки')
                    break;
                case 1:
                    console.log('   Запрос имеет некорректный формат')
                    break;
                case 2:
                    console.log('   Указанный в запросе код маркировки имеет некорректный формат (не распознан)')
                    break;
            }

            const statusItem = await posImpl.getInt()  // Сведения о статусе товара (тег 2109)
            console.log('Сведения о статусе товара (тег 2109)')
            switch (statusItem){
                case 0:
                    console.log('   Нет в документации')
                    break;
                case 1:
                    console.log('   Планируемый статус товара корректен')
                    break;
                case 2:
                    console.log('   Планируемый статус товара некорректен')
                    break;
                case 3:
                    console.log('   Оборот товара приостановлен')
                    break;
            }

            console.log("Check Mark: ", result, cause, requestResult, codeProcessingRequest, statusItem)
            return {result: result, cause: cause, requestResult: requestResult, codeProcessingRequest: codeProcessingRequest, statusItem: statusItem}
        } catch (NoMoreData){
            console.log("no more data")
        }
    }
}

class IncludeKm extends Marks{
    getType() {return 2}
    constructor(mode){
        super()
        this.pushInt(mode)  // 0 -  исключить, 1 - включить
    }
    async readAnswer(posImpl){
        try{
            await super.readAnswer(posImpl)
            const result = await posImpl.getInt()  // Результат проверки КМ в ФН (тег 2106)
            print2106(result)
        } catch (NoMoreData){
            console.log("no more data")
        }
    }
}

class SetKm extends Marks{
    getType() {return 15}
    constructor(mark, status, tag2106){
        super()
        this.pushString(mark)
        this.pushInt(status) // тег 2110
        this.pushInt(0) //Режим обработки кода маркировки (тег 2102) = 0
        this.pushInt(tag2106)//  Результат проведенной проверки КМ (тег 2106)
        this.pushInt(tag2108_picece)  // Мера количества (тег 2108)
    }
    async readAnswer(posImpl){
        try{
            await super.readAnswer(posImpl)
        } catch (NoMoreData){
            console.log("no more data")
        }
    }
}

class NullingCheck extends CommandViki{  // CancelCheck
    getCode(){return 0x32}
}

class ExtError extends CommandViki{
    getCode(){return 0x06}
    constructor(){
        super();
        this.pushInt(1)
    }
    async readAnswer(posImpl){
        try{
            console.log("ExtError readAnswer")
            let num = await posImpl.getInt()
            console.log("ReqNum: ", num)
            let code = await posImpl.getInt()
            console.log("code: ", code)
            let text = await posImpl.getString()
            console.log("Err text: ", text)
        } catch (NoMoreData){
            console.log("no more data")
        }
    }
}


class GetDateTime extends CommandViki{
    getCode(){return 0x13}
    constructor(){
        super();
    }
    async readAnswer(posImpl){
        try{
            let date = await posImpl.getString()
            console.log("Date: ", date)
            let time = await posImpl.getString()
            console.log("time: ", time)
        } catch (NoMoreData){
            console.log("no more data")
        }
    }
}

class StartWork extends CommandViki{
    constructor(){
        super();
        const d=format(new Date(),'ddMMyy')
        console.log(d)
        this.pushString(d) // дата
        const t=format(new Date(),'HHmmss')
        this.pushString(t) // время
    }
    getCode(){return 0x10}
}

class XReport extends CommandViki{
    constructor(){
        super();
        this.pushString('Васильева О.Е.')
    }
    getCode(){return 0x20}
}


export {CommandViki, TestConnection, Beep, PullPaper, StartWork, XReport, OpenShift, CloseShift, OpenCheck, CloseCheck, 
        Income, Pay, NullingCheck, GetPosStatus, CutPaper, ExtError, GetCounters, GetDateTime, GetInfo, GetPrinterStatus, 
        GetExchangeStatus, AdditionalInfo, CheckKm, IncludeKm, SetKm, tag2108_picece, }
