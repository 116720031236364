import axios from 'axios';

export const refreshPageMixin = {
    data() {
        return {
            currentHashIndex: '{{POST_BUILD_ENTERS_HASH_HERE_index}}',
            currentHashPos: '{{POST_BUILD_ENTERS_HASH_HERE_pos}}',
            currentHashChunkVendors: '{{POST_BUILD_ENTERS_HASH_HERE_chunk-vendors}}',
            currentHashChunkCommon: '{{POST_BUILD_ENTERS_HASH_HERE_chunk-common}}',
            currentVersionBuildTime: '{{POST_BUILD_VERSION_BUILD_TIME}}',
            token: localStorage.getItem('user-token'),
            hashChanged: false,
            hashChangedNew: false,
            newHash: ''
        }
    },
    watch: {
        '$route' (to, from) {
            if (this.hashChangedNew)
                this.reloadApp();
        }
    },
    methods: {
        initVersionCheck(url, type, frequency = 1000 * 60 * 10) {
            this.checkVersion(url, type);
            setInterval(() => {
                this.checkVersion(url, type);
            }, frequency);
        },
        async checkVersion(url, type) {
            try {
                const fileResponse = await axios.get(`${url}?t=${new Date().getTime()}`);
                if (type=='index'){
                    //console.log('hash-index', this.currentHashIndex, fileResponse.data[`hash-index`])
                    this.hashChangedNew |= this.hasHashChanged(this.currentHashIndex, fileResponse.data[`hash-index`]);
                }
                else if (type=='pos'){
                    //console.log('hash-pos', this.currentHashPos, fileResponse.data[`hash-pos`])
                    this.hashChangedNew |= this.hasHashChanged(this.currentHashPos, fileResponse.data[`hash-pos`]);
                }
                //console.log('hash-chunk-common', this.currentHashChunkCommon, fileResponse.data[`hash-chunk-common`])
                this.hashChangedNew |= this.hasHashChanged(this.currentHashChunkCommon, fileResponse.data[`hash-chunk-common`]);
                //console.log('hash-chunk-vendors', this.currentHashChunkVendors, fileResponse.data[`hash-chunk-vendors`])
                this.hashChangedNew |= this.hasHashChanged(this.currentHashChunkVendors, fileResponse.data[`hash-chunk-vendors`]);
            } catch (error) {
                this.loading = false;
                if (!error.response) {
                    this.errorStatus = 'Error: Network Error'
                } else {
                    this.errorStatus = error.response.data.message;
                }
            }
        },
        hasHashChanged(currentHash, newHash) {
            if (!currentHash || currentHash.startsWith('{{POST_BUILD_ENTERS_HASH_HERE')) {
                return false;
            }
            return currentHash !== newHash;
        },
        reloadApp() {
            caches.keys().then(function(names) {
                for (let name of names)
                    caches.delete(name);
            });
            console.log('reload');
            //window.location.href='https://kirsa.9733.ru/?'+(new Date()).getTime();
            window.location.reload();
            this.hashChangedNew = false;
        }
    }
};